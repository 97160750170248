/**
 * Attach an event listener for the "scrollend" event.
 *
 * If the current User-Agent does not support "scrollend"
 * (e.q. WebKit-based User-Agents), a fallback will be used.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollend_event
 */
export const addScrollEndEventListener = async (
  eventTarget: Element | Document,
  handler: (event: Event) => void
): Promise<void> => {
  if (!("onscrollend" in eventTarget)) {
    (await import("./scrollend-event-fallback")).addScrollendFallbackDispatcher(
      eventTarget
    );
  }

  eventTarget.addEventListener("scrollend", handler);
};
